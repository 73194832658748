@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,body {
    height: 100%;
    background-color: #f2f2f2;
}

div#root { 
    min-height: 100%;
    display: flex;
    flex-direction: column;
}